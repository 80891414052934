import './app.css';
import React, {Suspense, useEffect, useState} from "react";
import {HashRouter, Route, Routes, useLocation} from "react-router-dom";
import Home from "./components/home";
import Page404 from "./pages/404";
import {useGlobalContext} from "./context";
import GlooweLogoSmall from "./assets/images/gloowe-logo-01-sm.png";

const StoreCheck = (props) => {
    const context = useGlobalContext();
    const layoutMenuDomain = window.location.pathname.replace('/', '');
    const [storeExists, setStoreExists] = useState(false);
    const [isSearchingEnded, setIsSearchingEnded] = useState(false);

    useEffect(() => {
        const checkStore = async () => {
            return await context.CheckAndSetLayoutMenuDomain(layoutMenuDomain);
        };

        if (0 < layoutMenuDomain.length && context.LAYOUT_MENU_DOMAIN === null) {
            checkStore().then(response => {
                setIsSearchingEnded(true);
                setStoreExists(response);
            });
        }
    }, [layoutMenuDomain]);

    if (!storeExists) return(
        <section className="py-0">
            <div className="container">
                <div className="flex-center min-vh-100 py-6 row">
                    <div className="col-xxl-5 col-xl-6 col-lg-7 col-md-9 col-sm-11">
                        <div className="d-flex flex-center fw-bolder fs-5 mb-4">
                            <img id="navbar-logo" alt="gloowe" role="presentation" className={"logo-small"} src={GlooweLogoSmall} style={{width: '120px'}}/>
                        </div>
                        <div className="text-center card">
                            <div className="p-5 card-body">
                                {
                                    (layoutMenuDomain.length === 0)
                                        ? <>
                                            <div className="display-1 text-300 fs-error fs-7 fs-sm-8 fs-md-9">OMG</div>
                                            <p className="lead mt-4 text-800 font-sans-serif fw-semi-bold"><strong className={"me-1"}>Ops!</strong>Nessun store trovato</p>
                                            <p className={"fw-light font-base"}>Se stai visualizzando questa pagina è perché non hai nessuno store da mostrare.</p>
                                        </>
                                        : <>
                                            <div className="display-1 text-300 fs-error fs-7 fs-sm-8 fs-md-9">{isSearchingEnded ? '404' : '...'}</div>
                                            <p className="lead mt-4 text-800 font-sans-serif fw-semi-bold">
                                                {isSearchingEnded ? <><strong className={"me-1"}>Ops!</strong>La store che stai cercando non è stata trovato.</> : <>Stiamo cercando lo store.</>}
                                            </p>
                                        </>
                                }
                                {
                                    isSearchingEnded &&
                                    <>
                                        <hr/>
                                        <p className={"font-sans-serif fs--1"}>Assicurati che il nome dello store sia corretto e che sia ancora attivo. Se pensi che questo sia un errore, <a href="mailto:team@gloowe.com" className="ms-1 text-primary">contattaci</a>.</p>
                                        <a target={"_blank"} className="btn btn-primary mt-3 text-white" href={"https://www.gloowe.com"}>Vai su gloowe</a>
                                    </>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );

    return props.children;
};

const App = () => {
    return (
        <Suspense fallback="loading">
            <HashRouter>
                <Routes>
                    <Route path="/" element={<StoreCheck><Home/></StoreCheck>}/>
                    <Route path="/*" element={<Page404/>}/>
                </Routes>
            </HashRouter>
        </Suspense>
    );
    /*
    *
    * return (<Suspense fallback={<LoadingPage/>}>
        <BrowserRouter>
            <Routes>
                <Route exact path="/" element={<ProtectedRoute><LazyHomepage/></ProtectedRoute>}/>
                <Route exact path="/:nickname" element={<NoAuthRoute><LazyShow/></NoAuthRoute>}/>
                <Route path="/*" element={<Page404/>}/>
            </Routes>
        </BrowserRouter>
    * */
}

export default App;
