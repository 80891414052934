import React, {useEffect, useState} from "react";
import {motion} from "framer-motion";
import AppPlaceholder from "../../utils/AppPlaceholder";
import {getFileUrl} from "../../utils/Utils";
import {useGlobalContext} from "../../context";

const Consumable = ({item}) => {
    const context = useGlobalContext();
    const [isDataComplete, setIsDataComplete] = useState(false);
    const [variationSelected, setVariationSelected] = useState(null);
    const [loadingRequest, setLoadingRequest] = useState(false);
    const [data, setData] = useState({current_consumable: '', current_image_consumable: null, has_variations: false});

    useEffect(() => {
        async function fetchData() {
            try {
                let image_consumable = getFileUrl(item, 'url_menu_consumable_cover');
                setData({
                    ...data
                    , current_consumable: item ?? ''
                    , current_image_consumable: image_consumable
                    , has_variations: !(item.num_variations === 0)
                });
                return true;
            } catch (e) {
                return false;
            }
        }

        fetchData().then((response) => {
            setIsDataComplete(response);
        });
    }, [context.dataUpdate]);

    function HandlerAddItem2Basket(item) {
        setLoadingRequest(true);
        context.AddItem2Basket(item, variationSelected)
        setTimeout(() => {
            setLoadingRequest(false)
        }, 1000);
    }

    function OnChangeVariation(variation) {
        setVariationSelected((variation === variationSelected) ? null : variation);
    }

    if (!isDataComplete) return <AppPlaceholder rows={3}/>;

    return (
        <div className="consumable-item p-3 pb-0 bg-white mb-2">
            <div className="row align-items-center">
                <div className={(data.current_image_consumable !== null ? 'col-12 col-sm-8' : 'col-12') + ' mb-2 mb-sm-0'}>
                    <div className="row align-items-center">
                        <div>
                            <h6 className="mb-1 fw-bolder fs-2 text-dark">{data.current_consumable.name}</h6>
                            {data.current_consumable.description && <span className="text-muted text-sm">{data.current_consumable.description}</span>}
                        </div>
                        <div className={"w-100 py-2 py-md-4"}>
                            {
                                (item.num_variations === 0)
                                    ? <span className="text-md mr-4 fw-semi-bold">€ <span>{parseFloat(item.price).toFixed(2)}</span></span>
                                    : item.list_variation.map((variation, index) => (
                                        <div key={index.toString()} className={"col-12 py-1"}>
                                            <div className={"d-flex"}>
                                                <div className={"me-2"}>
                                                    <input type={"checkbox"} checked={variationSelected === variation} onChange={() => OnChangeVariation(variation)}/>
                                                </div>
                                                <div className={"flex-1"}>
                                                    <span className="text-md mr-4 fw-semi-bold">€<span className={"ms-1"}>{parseFloat(variation.price).toFixed(2)}</span><small className={"m-2"}>{variation.name}</small></span>
                                                </div>
                                            </div>
                                        </div>
                                    ))
                            }
                        </div>
                    </div>
                </div>
                {
                    data.current_image_consumable &&
                    <div className="col-12 col-sm-4 mb-4 mb-sm-0 text-end">
                        <img alt={data.current_consumable.title} src={data.current_image_consumable}
                             className={"consumable-image image aspect-radio-1-1"}
                             style={{borderRadius: '5%'}}/>
                    </div>
                }
            </div>
            {
                (context.dataStoreSettings.store_enabled_orders === true) &&
                <div className="row align-items-center">
                    <motion.button className={"btn " + (loadingRequest ? "bg-dark" : "bg-primary") + " text-light d-flex flex-center border-bottom-10px"}
                                   disabled={(loadingRequest || (data.has_variations > 0 && variationSelected === null))} onClick={() => HandlerAddItem2Basket(item)}>
                        <label className={"m-0 fw-bold fs--1"}>{loadingRequest ? "Aggiungo ...": "Aggiungi all'ordine"}</label>
                    </motion.button>
                </div>
            }
        </div>
    );
};

export const Content = ({description, data_consumable}) => {
    return (
        <motion.div className="consumable-content p-0" variants={{collapsed: {scale: 0.8}, open: {scale: 1}}} transition={{duration: 0.8}}>
            {description && <span>{description}</span>}
            {
                data_consumable && data_consumable.map((item, index) =>
                    <Consumable key={index.toString()} item={item}/>
                )
            }
        </motion.div>
    );
}
